// Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const NEW_CLIENT_STEP1_START = 'NEW_CLIENT_STEP1_START';
export const NEW_CLIENT_STEP1_SUCCESS = 'NEW_CLIENT_STEP1_SUCCESS';
export const NEW_CLIENT_STEP1_FAIL = 'NEW_CLIENT_STEP1_FAIL';

export const NEW_CLIENT_STEP2_START = 'NEW_CLIENT_STEP2_START';
export const NEW_CLIENT_STEP2_SUCCESS = 'NEW_CLIENT_STEP2_SUCCESS';
export const NEW_CLIENT_STEP2_FAIL = 'NEW_CLIENT_STEP2_FAIL';

export const NEW_CLIENT_STEP3_START = 'NEW_CLIENT_STEP3_START';
export const NEW_CLIENT_STEP3_SUCCESS = 'NEW_CLIENT_STEP3_SUCCESS';
export const NEW_CLIENT_STEP3_FAIL = 'NEW_CLIENT_STEP3_FAIL';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const DELETE_CLIENT_START = 'DELETE_CLIENT_START';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

