import { AUTH_START, AUTH_SUCCESS, AUTH_FAIL, LOGOUT_SUCCESS } from '../actions/actionsType';

const initialState = {
    token: null,
    name: null,
    lastname: null,
    email: null,
    genericPassword: true,
    role: null,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case AUTH_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case AUTH_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                name: action.payload.user.name,
                lastname: action.payload.user.lastname,
                email: action.payload.user.email,
                genericPassword: action.payload.user.genericPassword,
                role: action.payload.user.role,
                error: null,
                loading: false
            }
        case AUTH_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
            }
        case LOGOUT_SUCCESS:
            return {
                token: null,
                name: null,
                lastname: null,
                email: null,
                genericPassword: null,
                role: null,
                error: null,
                loading: false
            }
        default:
            return state;
    }
}