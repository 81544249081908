import {
    NEW_CLIENT_STEP1_START, NEW_CLIENT_STEP1_SUCCESS, NEW_CLIENT_STEP1_FAIL,

    NEW_CLIENT_STEP2_START, NEW_CLIENT_STEP2_SUCCESS, NEW_CLIENT_STEP2_FAIL,

    NEW_CLIENT_STEP3_START, NEW_CLIENT_STEP3_SUCCESS, NEW_CLIENT_STEP3_FAIL,
} from '../actions/actionsType';

const initialState = {
    dataStep1: null,
    dataStep2: null,
    dataStep3: null,
    error: null,
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        // ----------------STEP 1---------------
        case NEW_CLIENT_STEP1_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case NEW_CLIENT_STEP1_SUCCESS:
            return {
                ...state,
                dataStep1: action.payload,
                error: null,
                loading: false
            }
        case NEW_CLIENT_STEP1_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
            }
        // ----------------STEP 2---------------
        case NEW_CLIENT_STEP2_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case NEW_CLIENT_STEP2_SUCCESS:
            return {
                ...state,
                dataStep2: action.payload,
                error: null,
                loading: false
            }
        case NEW_CLIENT_STEP2_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
            }
        // ----------------STEP 3---------------
        case NEW_CLIENT_STEP3_START:
            return {
                ...state,
                loading: true,
                error: false
            }
        case NEW_CLIENT_STEP3_SUCCESS:
            return {
                ...state,
                dataStep3: action.payload,
                error: null,
                loading: false
            }
        case NEW_CLIENT_STEP3_FAIL:
            return {
                ...state,
                error: true,
                loading: false,
            }
        default:
            return state;
    }
}