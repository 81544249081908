// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barrio-bosques-js": () => import("./../../../src/pages/barrio/bosques.js" /* webpackChunkName: "component---src-pages-barrio-bosques-js" */),
  "component---src-pages-barrio-el-canal-js": () => import("./../../../src/pages/barrio/el-canal.js" /* webpackChunkName: "component---src-pages-barrio-el-canal-js" */),
  "component---src-pages-barrio-el-portal-js": () => import("./../../../src/pages/barrio/el-portal.js" /* webpackChunkName: "component---src-pages-barrio-el-portal-js" */),
  "component---src-pages-barrio-index-js": () => import("./../../../src/pages/barrio/index.js" /* webpackChunkName: "component---src-pages-barrio-index-js" */),
  "component---src-pages-barrio-los-lagos-js": () => import("./../../../src/pages/barrio/los-lagos.js" /* webpackChunkName: "component---src-pages-barrio-los-lagos-js" */),
  "component---src-pages-barrio-saltos-js": () => import("./../../../src/pages/barrio/saltos.js" /* webpackChunkName: "component---src-pages-barrio-saltos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-dashboard-clientes-detalles-js": () => import("./../../../src/pages/dashboard/clientes/detalles.js" /* webpackChunkName: "component---src-pages-dashboard-clientes-detalles-js" */),
  "component---src-pages-dashboard-clientes-index-js": () => import("./../../../src/pages/dashboard/clientes/index.js" /* webpackChunkName: "component---src-pages-dashboard-clientes-index-js" */),
  "component---src-pages-dashboard-nuevo-cliente-js": () => import("./../../../src/pages/dashboard/nuevo-cliente.js" /* webpackChunkName: "component---src-pages-dashboard-nuevo-cliente-js" */),
  "component---src-pages-dashboard-usuario-cambio-de-clave-js": () => import("./../../../src/pages/dashboard/usuario/cambio-de-clave.js" /* webpackChunkName: "component---src-pages-dashboard-usuario-cambio-de-clave-js" */),
  "component---src-pages-dashboard-usuario-cuotas-js": () => import("./../../../src/pages/dashboard/usuario/cuotas.js" /* webpackChunkName: "component---src-pages-dashboard-usuario-cuotas-js" */),
  "component---src-pages-dashboard-usuario-vencimientos-js": () => import("./../../../src/pages/dashboard/usuario/vencimientos.js" /* webpackChunkName: "component---src-pages-dashboard-usuario-vencimientos-js" */),
  "component---src-pages-dashboard-vencimientos-index-js": () => import("./../../../src/pages/dashboard/vencimientos/index.js" /* webpackChunkName: "component---src-pages-dashboard-vencimientos-index-js" */),
  "component---src-pages-el-faro-js": () => import("./../../../src/pages/el-faro.js" /* webpackChunkName: "component---src-pages-el-faro-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

