import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
import { createStore, applyMiddleware, compose } from "redux"
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from "redux-thunk"
import rootReducer from "../reducers/index"
// import { loadDevTools } from './reduxDevTools.js'


const initialState = {}
const middleware = [thunk]

const persistConfig = { // configuration object for redux-persist
    key: ['auth', 'newClient'],
    storage,
}


const persistedReducer = persistReducer(persistConfig, rootReducer, initialState) // create a persisted reducer

const store = createStore(
    persistedReducer,
    compose(applyMiddleware(...middleware))
    // compose(applyMiddleware(...middleware), loadDevTools())
)
const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export default ({ element }) => <Provider store={store}> <PersistGate loading={null} persistor={persistor}>{element}</PersistGate></Provider>
